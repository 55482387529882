import React from 'react';

import { AtomSpinner, Button, Modal } from '@moved/ui';
import { useModal } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../../common';
import { useOrientationContents, useOrientationContentsPending } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const PreviewChapter = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {

  const chapters = useOrientationContents(id)
  const pending  = useOrientationContentsPending();

  const chapter = chapters.find(content => parseInt(content.id) === parseInt(dataCollection?.content_editor_content_ids?.at(0)));

  const modal = useModal();

  if(pending) return <AtomSpinner/>;

  return (
    <>
      <Modal.Title><span className={CSS.progress}>{progress}/{length}</span> Chapter preview</Modal.Title>

      <Modal.Content>
        <AdminContentRenderer content={chapter.content} />
      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
        />
        <Button
          text='Next'
          color='primary'
          icon={{ symbol: 'Arrow-right', library: 'navigation' }}
          iconPosition='right'
          onClick={() => setProgress(progress + 1)}
        />
      </Modal.Actions>
    </>
  );
};
