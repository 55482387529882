import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, Button } from '@moved/ui';
import { format, useModal, useNotify } from '@moved/services';

import { cloneOrientationChapter } from '../../actions';
import { useEligibleProperties, useCloneOrientationChapterPending, useOrientationContents } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';


export const ConfirmChoices = ({ id, dataCollection, setSuccess, progress, setProgress, length }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();

  const eligible = useEligibleProperties(id);
  const updatePending = useCloneOrientationChapterPending();
  const contents = useOrientationContents(id);

  const {
    content_editor_content_ids,
    building_ids
  } = dataCollection;

  const chosenChapters = content_editor_content_ids.map(id => contents.find(content => parseInt(content.id) === parseInt(id)));
  const chosenBuildings = building_ids.map(id => eligible.find(property => parseInt(property.building.id) === parseInt(id)));

  const submit = () => {
    dispatch(cloneOrientationChapter(id, dataCollection))
      .then(resp => {
        notify.default(`Chapters cloned!`);
        setSuccess(resp);
        return setProgress(progress+1);
      })
      .catch(error => notify.error(format.error(error)));
  };


  return (
    <>
      <Modal.Title>
        <span className={CSS.progress}>{progress}/{length}</span> Confirm selection
      </Modal.Title>

      <Modal.Content>
        <h4 className={CSS.selected_count}>Selected chapters <span>{chosenBuildings.length}</span></h4>
        <div className={CSS.pills}>
          {chosenChapters.map(chapter => (<div className={CSS.pill} key={chapter.id}>{chapter.title}</div>))}
        </div>

        <h4 className={CSS.selected_count}>Selected properties <span>{chosenBuildings.length}</span></h4>
        <div className={CSS.pills}>
          {chosenBuildings.map(property => (<div className={CSS.pill} key={property.building.id}>{property.building.name}</div>))}
        </div>
      </Modal.Content>

      <Modal.Actions>
        <div className='stackHorizontal gap-16 justify-start flex-auto'>
          <Button
            text='Back'
            icon={{ symbol: 'Arrow-left', library: 'navigation' }}
            color='secondary'
            onClick={() => setProgress(progress - 1)}
          />
        </div>
        <div className='stackHorizontal gap-16 justify-end flex-auto'>
          <Button
            text='Cancel'
            color='secondary'
            disabled={updatePending}
            onClick={() => modal.close()}
          />
          <Button
            text='Confirm & Clone'
            color='primary'
            icon={{ symbol: 'Arrow-right', library: 'navigation' }}
            iconPosition='right'
            disabled={updatePending}
            onClick={submit}
          />
        </div>
      </Modal.Actions>
    </>
  );
};
