import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { format, useUser } from '@moved/services';
import { ClipboardText, Icon } from '@moved/ui';

import CSS from './styles/LeaseDetails.module.scss';

export const LeaseDetails = ({ lease, tenant, building, stepType }) => {
  const { hasDomain, hasAbilities } = useUser();
  return (
    <div className={CSS.lease_info}>
      <div className={CSS.row}>
        { stepType === 'move-out' && (
          <div className={CSS.block}>
            <label>
              { tenant?.planned_move_out_date ? 'Move out' : 'Lease end' }
            </label>
            <span className={CSS.value}>
              { format.date(tenant.planned_move_out_date) ?? format.date(lease.end_date) ?? '—' }
            </span>
          </div>
        )}
        { stepType === 'move-in' && (
          <div className={CSS.block}>
            <label>
              { lease?.occupancy_start_date ? 'Move in' : 'Lease start' }
            </label>
            <span className={CSS.value}>
              { format.date(lease.occupancy_start_date) ?? format.date(lease.start_date) ?? '—' }
            </span>
          </div>
        )}
        <div className={CSS.block}>
          <label>Property</label>
          { hasAbilities('ReadBuildings') ? (
            <Link className={classNames('stackHorizontal gap-4 items-center contentPrimary',CSS.value)} to={`/admin/properties/${building.id}`}>
              { building?.name }
              <Icon symbol='Up-right' library='navigation' size='20px' color='inherit'/>
            </Link>
          ) : (
            <span className={CSS.value}>{ building?.name }</span>
          )}
        </div>
        { lease?.address?.unit && (
          <div className={CSS.block}>
            <label>Unit</label>
            <span className={CSS.value}>{ lease?.address?.unit }</span>
          </div>
        )}
        { (hasDomain('cx') || hasDomain('abode')) && (
          <div className={CSS.block}>
            <label>Client</label>
            { hasAbilities('ReadPartners') ? (
              <Link className={classNames('stackHorizontal gap-4 items-center contentPrimary',CSS.value)} to={`/admin/clients/${building?.partner?.id}`}>
                { building?.partner?.name }
                <Icon symbol='Up-right' library='navigation' size='20px' color='inherit'/>
              </Link>
            ) : (
              <span className={CSS.value}>{ building?.partner?.name }</span>
            )}
          </div>
        )}
      </div>
      <div className={CSS.row}>
        { lease?.external_lease_number && (
          <div className={CSS.block}>
            <label>Lease Number</label>
            <ClipboardText text={lease?.external_lease_number} className={CSS.clipboard}/>
          </div>
        )}
        { (hasDomain('cx') || hasDomain('abode')) && building?.id && (
          <div className={CSS.block}>
            <label>Building ID</label>
            <ClipboardText text={`${building?.id}`} className={CSS.clipboard}/>
          </div>
        )}
        { (tenant,'external_contact_id') && (
          <div className={CSS.block}>
            <label>Contact ID</label>
            <ClipboardText text={tenant?.external_contact_id} className={CSS.clipboard}/>
          </div>
        )}
        { (hasDomain('cx') || hasDomain('abode')) && tenant?.id && (
          <div className={CSS.block}>
            <label>Tenant ID</label>
            <ClipboardText text={`${tenant?.id}`} className={CSS.clipboard}/>
          </div>
        )}
      </div>
    </div>
  );
};
