import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Options.module.scss';

export const Options = ({
  options=[],
  isOpen,
  onSelect,
  emptyContent='None available',
  className,
}) => {

  const [focusIndex, setFocusIndex] = useState();
  const focusRef = useRef();

  const handleSelect = useCallback((option, e) => {
    e?.stopPropagation();
    onSelect(option);
  },[onSelect]);

  // reset focus index when options change
  useEffect(() => setFocusIndex(),[options, isOpen]);

  // focus the selected option when the index changes
  useEffect(() => focusRef.current?.focus?.(),[focusIndex]);

  // attach listener to handle key bindings
  useEffect(() => {
    if(!isOpen) return;
    const handleKeyDown = (e) => {
      ['ArrowUp','ArrowDown','Enter'].includes(e.key) && e?.preventDefault();
      if(e.key === 'ArrowDown') setFocusIndex(Math.min((focusIndex ?? -1) + 1, options.length - 1));
      if(e.key === 'ArrowUp') setFocusIndex(Math.max((focusIndex ?? 0) - 1, 0));
      if(e.key === 'Enter' && options[focusIndex]) handleSelect(options[focusIndex]);
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  },[isOpen, focusIndex, options, handleSelect]);

  if(!isOpen) return null;
  return (
    <div className={classNames(CSS.optionList,className)}>
      { options.map(option => (
        <div
          className={CSS.option}
          key={option.key ?? option.label}
          onClick={(e) => handleSelect(option,e)}
          tabIndex='-1'
          ref={focusIndex === options.indexOf(option) ? focusRef : null}
        >
          { option?.label }
        </div>
      ))}
      { options.length === 0 && <div className={CSS.empty}>{ emptyContent }</div> }
    </div>
  )
};

Options.propTypes = {
  /** Available options in the list */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Value to use for this option */
      value: PropTypes.any.isRequired,
      /** Label text for this option */
      label: PropTypes.node.isRequired,
      /** Key to use for uniqueness if label is not a string (optional) */
      key: PropTypes.string,
    })
  ).isRequired,
  /** Boolean control flag to display or hide the list of options */
  isOpen: PropTypes.bool,
  /** Handler function when an option is selected */
  onSelect: PropTypes.func,
  /** Class name to add to the list container */
  className: PropTypes.string,
};
