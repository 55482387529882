import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useProperty } from '../actions/selectors';

export const PropertyTabRedirect = () => {
  const history = useHistory();
  const { property_id } = useParams();
  const property = useProperty(property_id);

  useEffect(() => {
    const { pathname } = history.location;
    history.replace(property?.building_task_lists?.length > 0 ?
      `${pathname}/task-lists/${property?.building_task_lists?.at(0)?.id}` :
      `${pathname}/settings`,
    );
  },[history, property]);

  return false;
};
