import { ApprovalSearchWrapper } from './components';

const routes = [
  {
    path: '/approvals',
    name: 'ApprovalSearch',
    component: ApprovalSearchWrapper,
    exact: true,
    breadcrumb: {
      label: 'Approvals',
      icon: { symbol: 'Like', library: 'general' },
    },
  },
];

export default routes;
