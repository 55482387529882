import React from 'react';

import { RouteTree } from '@moved/services';
import { ProxyModeBadge } from '@moved/product';

import { Snow } from './Snow';

import CSS from '../styles/App.module.scss';

export const App = () => (
  <div className={CSS.admin_app}>
    <RouteTree />
    <ProxyModeBadge/>
    <Snow />
  </div>
);
