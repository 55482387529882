import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { get } from 'lodash';

import { LoaderOverlay, Icon, TabSlider } from '@moved/ui';
import { useNotify, format, useUser } from '@moved/services';

import { getClient } from '../../../../common/actions';
import { useClient, useClientPending } from '../../../../common/actions/selectors';

import CSS from './styles/ClientDetails.module.scss';

export const ClientDetailsBreadcrumb = ({ label, params }) => {
  const client = useClient(params?.clientId);
  return client?.name ?? label;
};

export const ClientDetails = ({ children, match }) => {
  // HOOKS
  const { clientId }  = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();
  const { hasDomain } = useUser();

  // CLIENTS STATE
  const client = useClient(clientId);
  const pending = useClientPending();

  useEffect(() => {
    dispatch(getClient(clientId))
      .catch(error => notify.error(format.error(error)));
  },[clientId, dispatch, notify]);

  if(!client) return null;

  // Note: must match available child routes
  const tabs = [
    { label: "Settings", value: `${match.url}` },
    hasDomain('abode') && { label: "Metrics", value: `${match.url}/metrics` },
  ].filter(v=>v);

  return (
    <>
      <Helmet>
        <title>{`${client.name} Details : Moved`}</title>
      </Helmet>

      { pending && <LoaderOverlay/> }

      <div className={CSS.content}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            { hasDomain('abode') && (
              <div className={CSS.back_btn} onClick={() => history.push(`/admin/clients${Cookies.get('clientSearch') ?? ''}`)}>
                <Icon symbol='Arrow-left' library='navigation' size='32px' />
              </div>
            )}
            <h1>{ get(client,'name') }</h1>
          </div>
        </div>

        { tabs.length > 1 && (
          <TabSlider
            list={tabs}
            active={history.location.pathname}
            callback={view => history.push(view)}
          />
        )}

        <div className={CSS.details_section}>
          { children }
        </div>

      </div>
    </>
  );
};
