import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, merge } from 'lodash';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { fromEvent } from 'file-selector';

import { format } from '@moved/services';

import { Icon } from '../../../sondheim/components/Icon';
import { Notebox } from '../../../sondheim/components/Notebox';
import { AtomSpinner } from '../AtomSpinner';
import { UploaderFilePreview } from './UploaderFilePreview';

import CSS from './Uploader.module.scss';

/*
*  Helper function to pre-process files:
*   - append the dimensions to all image files
*/
const processFile = async file => {
  // handle additional image processing
  if(file?.type?.includes('image/')) {
    // create an image object to determine the image dimensions
    const bitmap = await createImageBitmap(file);
    const { width, height } = bitmap;
    file.width = width;
    file.height = height;
    return file;
  }
  else return file;
};

/*
*  Helper function to create a summary of file restrictions for the basic dzOptions
*   - only file type and file size are native to this library
*   - additional restrictions need to be added via `validator` function and
*      exposed to the UI via the `content` prop
*/
const getRestrictions = (options) => {
  return [
    options.minSize && `minimum ${Math.round(options.minSize/100000)/10} MB`, // convert to MB with maximum 1 decimal place
    options.maxSize && `maximum ${Math.round(options.maxSize/100000)/10} MB`, // convert to MB with maximum 1 decimal place
    options.accept && `only files of type ${Object.keys(options.accept).map(type => options.accept[type].join(', ')).join(', ')}`,
  ].filter(v=>v).join(', ');
};

/*
*  Uploader based on react-dropzone and dropzone.js
*  Full configuration is exposed through the options prop
*  docs here: https://www.dropzonejs.com/#configuration-options
*/
export const Uploader = ({ dzOptions, onSelect, onReject, content, icon, className, initialFiles=[], error }) => {
  const [processing, setProcessing] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState(initialFiles);

  // declare default dropzone config and merge with overrides
  const config = merge(
    {
      autoProcessQueue: false,
      getFilesFromEvent: async event => {
        const files = await fromEvent(event);
        if(event.type === 'dragenter') return files; // don't process files until dropped
        setProcessing(true);
        return await Promise.all(Array.from(files).map(processFile))
          .finally(() => setProcessing(false));
      },
      onDrop: (accepted, rejected) => {
        setAcceptedFiles(accepted);
        if(onSelect) onSelect(accepted); // always call onSelect even if empty
        if(onReject && rejected.length) onReject(rejected); // only call onReject if there are rejected drops
      },
    },
    dzOptions,
  );

  const restrictions = getRestrictions(config);

  // load dropzone
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone(config);

  const onRemoveFile = (fileToRemove) => {
    const newFileList = acceptedFiles.filter(file => file !== fileToRemove);
    setAcceptedFiles(newFileList);
    if(onSelect) onSelect(newFileList);
  };

  return processing ? (
    <div className={classNames(CSS.dropzone, className, CSS.disabled)}>
      <div className={CSS.icon}><AtomSpinner size={27} /></div>
      <div className={CSS.copy}>processing&hellip;</div>
    </div>
  ) : (
    <>
      { fileRejections.length > 0 && (
        <Notebox
          className={CSS.error_notebox}
          color='red'
          icon={{ symbol: 'Error-circle', library: 'code' }}
          title='Validation error'
          body={(
            <>
              { fileRejections.map(({file,errors}) => (
                <div key={file.name} className={CSS.error_file}>
                  { fileRejections.length > 1 && (
                    <h4>{file.name}</h4>
                  )}
                  <ul className={CSS.errors}>
                    { errors.map(error => (
                      <li key={error.code}>{ error.message }</li>
                    ))}
                  </ul>
                </div>
              ))}
            </>
          )}
        />
      )}
      <div {...getRootProps()}
        className={classNames(
          CSS.dropzone,
          className,
          {
            [CSS.active]: isDragActive,
            [CSS.selected]: (acceptedFiles && acceptedFiles.length),
            [CSS.error]: error,
          }
        )}
      >
        <input {...getInputProps()} />
        { acceptedFiles && acceptedFiles.length ? (
          <div className={CSS.preview_wrapper}>
            <div className={CSS.files_list}>
              { acceptedFiles.map(file => (
                <UploaderFilePreview file={file} key={file.name} onDelete={onRemoveFile} />
              ))}
            </div>
            <div className={CSS.hint}>
              Drag a new file here or <span className='faux-link'>browse</span> to replace
            </div>
          </div>
        ) : (
          <>
            <Icon
              size={get(icon,'size','40px')}
              symbol={get(icon,'symbol','Cloud-upload')}
              library={get(icon,'library','files')}
              className={CSS.icon}
            />
            <div className={CSS.copy}>Drag and drop here or <span className={'faux-link'}>browse</span> to upload</div>
            { restrictions && (
              <div className={CSS.hint}>
                { format.capitalize(restrictions) }
              </div>
            )}
            { content && (
              <div className={CSS.content_wrapper}>{ content }</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Uploader.propTypes = {
  /* Dropzone configuration options object */
  dzOptions: PropTypes.object,
  /* Callback function after file(s) selection, with list of files as arg */
  onSelect: PropTypes.func,
  /* Callback function after file(s) rejection, with list of files as arg */
  onReject: PropTypes.func,
  /* Optional content to render below the default content */
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Optional icon override */
  icon: PropTypes.object,
  /* Optional classname to use for the dropzone element */
  className: PropTypes.string,
};
