/*
* Custom service wrapper for moved scrollable parent container reference
*
* The hook for this context `useScroller` provides the following:
*   ref, set, goToTop
*
* This is a reference to the scrollable parent DOM element for use in
* onScroll bindings throughout the app for children components and a function
* for updating that element
*
* This context exposes three exports to implement scroller context
* See the examples below for how to implement each approach
*
* Use context hook for functional component
* example:
*    import { useScroller } from '@moved/services';
*    const scroller = useScroller();
*    scroller.ref.current.addEventListener('onscroll', (e) => ...do something...);
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* example:
*    import { ScrollerProvider } from '@moved/services';
*    render (
*      <ScrollerProvider> ... </ScrollerProvider>
*    )
*/
import React, { createContext, useContext, useRef } from "react";

const ScrollerContext = createContext();
const useScroller = () => useContext(ScrollerContext);

// define ScrollerContextProvider as functional component
const ScrollerProvider = ({ children }) => {
  const scrollerRef = useRef(window);
  const setScroller = (ref) => scrollerRef.current = ref;
  const goToTop = () => scrollerRef.current.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  const context = {
    ref: scrollerRef,
    set: setScroller,
    goToTop
  };
  return (
    <ScrollerContext.Provider value={context}>
      { children }
    </ScrollerContext.Provider>
  );
};

export { ScrollerProvider, useScroller };
