import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Accordion } from '../Accordion';

export const AccordionList = ({ panels=[], allowMultiple=false, className }) => {
  const [openStates, setOpenStates] = useState(panels.map(panel => (
    allowMultiple ?
      panel.initial === true :
      panels.find(panel => panel.initial === true) === panel
  )));

  const toggleOpen = (toggleIndex) => {
    setOpenStates(openStates.map((isOpen,index) => {
      if(toggleIndex === index) return !isOpen;
      else return allowMultiple ? isOpen : false;
    }));
  };

  return (
    <div className={classNames('stackVertical gap-16', className)}>
      { panels.map((panel,index) => (
        <Accordion
          key={index}
          className={panel.className}
          label={panel.label}
          content={
            (isOpen) => typeof panel.content === 'function' ?
              panel.content(isOpen, toggleOpen) :
              panel.content
          }
          onChange={() => toggleOpen(index)}
          open={openStates[index]}
          isControlled={true}
        />
      ))}
    </div>
  );
}

AccordionList.propTypes = {
  /** Array of panels (each takes the props of a single `Accordion`) */
  panels: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    initial: PropTypes.bool,
    className: PropTypes.string,
  })),
  /** Flag to allow multiple accordions to be expanded at once */
  allowMultiple: PropTypes.bool,
  /** Classname to add to the component */
  className: PropTypes.string,
};
