import React, { useEffect, useRef, useReducer, useCallback } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Collapsible.module.scss';

const COLLAPSED = "collapsed";
const COLLAPSING = "collapsing";
const EXPANDING = "expanding";
const EXPANDED = "expanded";
const collapsedStyle = { height: '0px', visibility: 'hidden' };
const expandedStyle = { height: '', visibility: '' };

function nextFrame(callback) {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
}

export const Collapsible = ({ open, className, children }) => {
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [,forceUpdate] = useReducer(_=>_+1,0);
  const elementRef = useRef();
  const state = useRef({
    collapse: open ? EXPANDED : COLLAPSED,
    style: open ? expandedStyle : collapsedStyle,
  }).current;

  const getElementHeight = () => `${elementRef.current.scrollHeight}px`;

  const setCollapsed = useCallback(() => {
    state.collapse = COLLAPSED;
    state.style = collapsedStyle;
    forceUpdate();
  },[state]);

  const setExpanded = useCallback(() => {
    state.collapse = EXPANDED;
    state.style = expandedStyle;
    forceUpdate();
  },[state]);

  const setCollapsing = useCallback(() => {
    state.collapse = COLLAPSING;
    state.style = {
      height: getElementHeight(),
      visibility: '',
    };
    forceUpdate();
    nextFrame(() => {
      if (state.collapse !== COLLAPSING) return;
      state.style = {
        height: '0px',
        visibility: '',
      };
      forceUpdate();
    });
  },[state]);

  const setExpanding = useCallback(() => {
    state.collapse = EXPANDING;
    nextFrame(() => {
      if (state.collapse !== EXPANDING) return;
      state.style = {
        height: getElementHeight(),
        visibility: ''
      };
      forceUpdate();
    });
  },[state]);

  // react to changes in the open prop to trigger the animations
  useEffect(() => {
    if(open && [COLLAPSED,COLLAPSING].includes(state.collapse)) setExpanding();
    if(!open && [EXPANDED,EXPANDING].includes(state.collapse)) setCollapsing();
  },[open,state,setExpanding,setCollapsing]);

  // register transition event listener to complete the transitions
  useEffect(() => {
    const element = elementRef?.current;
    const endTransition = ({ target, propertyName }) => {
      if (target === element && propertyName === "height") {
        const styleHeight = target.style.height;
        if (styleHeight === '') return;
        if (state.collapse === EXPANDING && styleHeight !== '0px') setExpanded();
        if (state.collapse === COLLAPSING && styleHeight === '0px') setCollapsed();
      }
    };
    element?.addEventListener('transitionend',endTransition);
    return () => element?.removeEventListener('transitionend',endTransition);
  },[setCollapsed,setExpanded,state]);

  return (
    <div
      ref={elementRef}
      style={state.style}
      className={classNames(
        CSS.collapsibleContent,
        CSS[state.collapse],
        state.collapse,
        className,
      )}
    >
      { typeof children === "function" ? children(open) : children }
    </div>
  );
}

Collapsible.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
};
