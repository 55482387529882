import axios from 'axios';
import moment from 'moment';

import { getTokens, refreshToken } from '../contexts/UserContext.js';
import { userService } from '../services/userService';

// Refresh Token Interceptor Helpers
let refreshing;
const shouldAuthRefresh = (config) => {
  const { tokenExpiration } = getTokens();
  return (
    !config.allowExpired && // endpoint config override
    (
      refreshing || // intercept all requests until existing refresh is resolved
      (tokenExpiration && moment.utc(tokenExpiration).isBefore()) // if current token has expired already
    )
  );
};

const appendAuthHeader = (config) => {
  const { token, superUserToken } = getTokens();
  if(!config.asSuperUser && token != null) config.headers.Authorization = `Bearer ${token}`;
  if(config.asSuperUser && superUserToken != null) config.headers.Authorization = `Bearer ${superUserToken}`;
  return config;
};

const AuthenticationInterceptor = config => {
  if(shouldAuthRefresh(config)) {
    if(!refreshing) refreshing = refreshToken().finally(() => refreshing = undefined); // create refresh request if one doesn't exist
    return refreshing.then(() => appendAuthHeader(config));
  } else {
    return appendAuthHeader(config);
  }
};

// Redirect Unauthorized API Interceptor Helpers
const shouldAuthRedirect = (response) => {
  return (
    response?.status === 401 &&
    !response?.request?.withCredentials &&
    !response?.config?.ignoreUnauthorized
  );
};

const AuthRedirectInterceptor = error => {
  if(shouldAuthRedirect(error?.response)) userService.redirect();
  return Promise.reject(error);
}

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL
});
request.defaults.headers.common['Content-Type'] = 'application/json';
request.CancelToken = axios.CancelToken;
request.interceptors.request.use(AuthenticationInterceptor);
request.interceptors.response.use(
  response => response, // don't do anything to successful responses
  AuthRedirectInterceptor // redirect on 401 responses
);
