import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';

import CSS from './Notebox.module.scss';

// TODO: backfill all places using 'orange' to use 'yellow' instead
export const Notebox = ({
  color='blue',
  icon,
  title,
  body,
  actions,
  className,
  style={},
}) => {
  // Not all colors have a corresponding color in the icon library, and some need to be overridden for contrast
  const iconColorMap = {
    success: 'green', // needs to be mapped
    info: 'blue', // needs to be mapped
    helpful: 'purple', // needs to be mapped
    warning: 'yellow', // needs to be mapped
    error: 'red', // needs to be mapped
    neutral: 'black', // needs to be mapped to high contrast
    white: 'black', // high contrast
    dark: 'white', // needs to be mapped to high contrast
    black: 'white', // high contrast
  };
  // Only the status colors have default icons
  const defaultIconMap = {
    success: { symbol: 'Done-circle', library: 'code' },
    info: { symbol: 'Info-circle', library: 'code' },
    warning: { symbol: 'Warning-2', library: 'code' },
    error: { symbol: 'Error-circle', library: 'code' },
  };
  return (
    <div
      className={classNames(
        'stackHorizontal gap-24 stackVertical--mobile',
        CSS.notebox,
        CSS[color],
        className
      )}
      style={style}
    >
      <div className='stackHorizontal gap-12 flex-auto'>
        { (icon ?? defaultIconMap[color]) && (
          <Icon
            symbol={icon?.symbol ?? defaultIconMap[color]?.symbol}
            library={icon?.library ?? defaultIconMap[color]?.library}
            color={icon?.color ?? iconColorMap[color] ?? color}
            size={icon?.size ?? '24px'}
            className={classNames(CSS.icon,icon?.className)}
          />
        )}
        <div className='stackVertical flex-grow'>
          { title && (
            <h4
              className={classNames(
                'labelM contentPrimary padding-2-0',
                CSS.title
              )}
            >
              { title }
            </h4>
          )}
          { body && (
            <div
              className={classNames(
                'labelM contentSecondary padding-2-0',
                CSS.body
              )}
            >
              { body }
            </div>
          )}
        </div>
      </div>
      { actions && (
        <div className={classNames(
          'stackHorizontal gap-12 self-center items-center justify-end',
          CSS.actions
        )}>
          { actions }
        </div>
      )}
    </div>
  );
};


Notebox.propTypes = {
  /** Color variation to display */
  color: PropTypes.oneOf([
    'success', 'green',
    'info', 'blue',
    'helpful', 'purple',
    'warning', 'yellow',
    'error', 'red',
    'neutral', 'white',
    'dark', 'black',
    'transparent',
    'orange' // deprecated
  ]),
  /** (optional) icon to display with the notebox */
  icon: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    library: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
  }),
  /** Title text of the notebox content */
  title: PropTypes.node,
  /** main body of the notebox content */
  body: PropTypes.node,
  /** (optional) include list of buttons as jsx to render them in the component */
  actions: PropTypes.node,
  /** (optional) class name to add to the component */
  className: PropTypes.string,
  /** (optional) styles to add to the component */
  style: PropTypes.object,
};
