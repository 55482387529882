import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_APPROVALS_GET_TASK_TITLES',
  request: () => request.get(`/partners/approvals/building-task-titles`).then(r => r?.data?.data?.building_task_titles),
  selector: (state) => state?.taskTitles,
  reducers: {
    success: (state, { response: titles }) => {
      return {
        ...state,
        taskTitles: titles,
      };

    }
  }
};

export const {
  reducer,
  action,
  useResource: useTaskTitles,
  usePending: useTaskTitlesPending,
} = generate.redux(creator);

export const initialState = {
  taskTitles: [],
};
