import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

import CSS from './Breadcrumbs.module.scss';

export const Breadcrumbs = ({ breadcrumbs=[], className }) => {
  const history = useHistory();

  if(breadcrumbs.length === 0) return null;

  return (
    <div className={classNames('stackHorizontal gap-8 items-center',className)}>
      { breadcrumbs.map((breadcrumb, index) => (
        <React.Fragment key={breadcrumb.url}>
          { index === breadcrumbs.length - 1 ? (
            <div className={classNames(CSS.breadcrumb, CSS.active)}>
              { breadcrumb?.icon?.symbol && (
                <Icon className={CSS.icon} {...breadcrumb.icon} />
              )}
              <span className='labelM'>
                { breadcrumb?.label }
              </span>
            </div>
          ) : (
            <>
              <Tooltip
                tooltip={breadcrumb?.tooltip ? (
                  <breadcrumb.tooltip
                    params={breadcrumb?.params}
                  />
                ) : (
                  breadcrumb?.label
                )}
              >
                <div
                  className={CSS.breadcrumb}
                  onClick={() => history.push(breadcrumb.url)}
                >
                  { breadcrumb?.icon ? (
                    <Icon className={CSS.icon} {...breadcrumb.icon} />
                  ) : (
                    <span className='labelM'>
                      { breadcrumb?.component ? (
                        <breadcrumb.component
                          label={breadcrumb?.label}
                          params={breadcrumb?.params}
                        />
                      ) : (
                        breadcrumb?.label
                      )}
                    </span>
                  )}
                </div>
              </Tooltip>
              <Icon
                symbol='Chevron-right'
                library='navigation'
                className={CSS.next_icon}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  /** Array of breadcrumbs to render */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label to display */
      label: PropTypes.string.isRequired,
      /** Icon to display */
      icon: PropTypes.shape({
        /** Icon symbol */
        symbol: PropTypes.string.isRequired,
        /** Icon library */
        library: PropTypes.string.isRequired,
      }),
      /** breadcrumb route url */
      url: PropTypes.string.isRequired,
      /** Tooltip component to render instead of the label (advanced) */
      tooltip: PropTypes.elementType,
      /** Component to render instead of the segment label (advanced) */
      component: PropTypes.elementType,
      /** Params to pass to component (should come from router service) */
      params: PropTypes.object,
    })
  ),
  /** Custom class name */
  className: PropTypes.string,
};
