import React from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import classNames from 'classnames';

import { useUser, format } from '@moved/services';
import { Icon, ClipboardText, Tooltip, Button } from '@moved/ui';

import CSS from './styles/TenantDetails.module.scss';

export const TenantDetails = ({ tenantEvent }) => {
  const history = useHistory();
  const { proxyAs, Can, hasDomain } = useUser();

  return (
    <div className={CSS.header}>
      <div className={CSS.title}>
        <h1>
          { format.fullname(tenantEvent?.tenant) }
          { tenantEvent?.move?.id && hasDomain('cx') && (
            <Can I='ReadMoves'>
              <Button
                size='small'
                color='tertiary'
                text='View in CX'
                icon={{ library: 'navigation', symbol: 'Chevron-right' }}
                iconPosition='right'
                onClick={() => history.push(`/cx/moves/${tenantEvent?.move?.id}/steps/${tenantEvent?.move_step_id}?search=${encodeURIComponent(tenantEvent?.tenant?.email)}`)}
              />
            </Can>
          )}
        </h1>
        <div className={CSS.subtitle}>
          <div className={CSS.id_row}>
            <div className={CSS.id_item}>
              <span className={CSS.label}>Email</span>
              <ClipboardText text={`${tenantEvent?.tenant?.email}`} className={CSS.clipboard}/>
            </div>
            { tenantEvent?.user_id && (
              <div className={CSS.id_item}>
                <span className={CSS.label}>User ID</span>
                <ClipboardText text={`${tenantEvent?.user_id}`} className={CSS.clipboard}/>
              </div>
            )}
            { tenantEvent?.move_id && (
              <div className={CSS.id_item}>
                <span className={CSS.label}>Move ID</span>
                <ClipboardText text={`${tenantEvent?.move_id}`} className={CSS.clipboard}/>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={CSS.oval_area}>
        <span className={CSS.back} onClick={() => history.push(`/admin/moves${Cookies.get('tenantSearch') ?? '?filter=upcoming'}`)}>
          <Icon symbol='Arrow-left' library='navigation' size='18px' />
          <span>Back to search</span>
        </span>
        <div className={CSS.actions}>
          { tenantEvent?.user_id && (
            <Can I={'ProxyAsUsers'}>
              <div className={CSS.action_item}>
                <Tooltip
                  customClass={CSS.popover}
                  placement={'bottom'}
                  tooltip={`Proxy as ${tenantEvent?.tenant?.firstname}`}
                >
                  <div className={classNames(CSS.oval, CSS.action)} onClick={() => proxyAs(tenantEvent?.user_id)}>
                    <Icon symbol='Disguise' library='design' size='24px' />
                  </div>
                </Tooltip>
              </div>
            </Can>
          )}
          <div className={CSS.oval}>
            <span>{format.initials(tenantEvent?.tenant)}</span>
          </div>
        </div>
      </div>
    </div>

  );
};
