import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { format, useModal, useUser } from '@moved/services';
import { Icon, PopOver } from '@moved/ui';

import { Minibar } from '../../../../common/components/Minibar';
import { SendInviteModal } from './SendInviteModal';
import { MoveStepTypeIcon } from '@moved/product';
import CSS from './styles/TenantEventSummary.module.scss';


export const TenantEventSummary = ({ tenantEvent, partner, active }) => {
  const modal = useModal();
  const { tenant } = tenantEvent;
  const { Can } = useUser();

  return (
    <Link
      className={classNames(CSS.box, { [CSS.box_active]: active })}
      to={`/admin/moves/${tenantEvent.id}`}
    >

      <div className={CSS.box_top}>

        <div className={CSS.direction}>
          {['move-out','move-in'].includes(tenantEvent?.move_step_type?.label) ? (
            <>
              <MoveStepTypeIcon type={tenantEvent?.move_step_type?.label} />
              <span>{tenantEvent?.move_step_type?.label === 'move-in' ? 'In' : 'Out'}</span>
            </>
          ) : (
            <span>{tenantEvent?.move_step_type?.display_name}</span>
          )}
        </div>

        <div className={CSS.unit}>{tenantEvent?.lease?.address?.unit ?? '—'}</div>

        <div className={CSS.name_area}>
          <div className={CSS.name}>
            <span className={CSS.first}>{tenant.lastname}</span>, <span className={CSS.last}>{tenant.firstname}</span>
          </div>
        </div>

        <div>{format.date(tenantEvent?.lease_date,'dateTruncated') ?? '—'}</div>

        <div>{tenantEvent?.building?.name}</div>

        <div className={CSS.progress}>
          {tenantEvent.user_id && tenantEvent?.move_tasks?.length > 0 ? (
            <Minibar tasks={tenantEvent.move_tasks.filter(task => task.requirement === 'required')} />
          ) : (
            <>
              <div className={CSS.pill}>Not registered</div>
              <Can I="CreateTenantEventReminders">
                {partner && (
                  <PopOver
                    id={'user_profile'}
                    customClass={CSS.reminder_popover}
                    hideArrow="true"
                    placement="top"
                    trigger="hover"
                    tooltip={<span>Send reminder</span>}
                  >
                    <div
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        modal.openLegacy(<SendInviteModal tenantEventID={tenantEvent.id} partnerID={partner.id} />, { hideCloseButton: true });
                      }}
                    >
                      <Icon symbol='Send' library='communication' className={CSS.reminder} />
                    </div>
                  </PopOver>
                )}
              </Can>
            </>
          )}
        </div>

        <div className={CSS.arrow_area}><Icon symbol='Chevron-right' library='navigation' className={CSS.arrow} /></div>

      </div>

    </Link>
  );
};
