import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Button,
  Form,
  FormText,
  FormToggle,
  Notebox
} from '@moved/ui';
import { format, useNotify, useUser } from '@moved/services';

import { updateQuestion, } from '../actions';
import { useUpdateQuestionPending } from '../actions/selectors';
import { QuestionOptions } from './QuestionOptions';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
});

const _handleReturn = (state, history, property_id) => {
  const from = state?.from;
  // If user came from search, it will be stored in state.from
  if(from) history.push(from);
  // else just go to the standard moves url
  else history.push(`/admin/properties/${property_id}`);
};

export const QuestionForm = ({ task, question }) => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notify = useNotify();
  const { hasAbilities, Can } = useUser();

 // Get id from URL
  const { property_id } = useParams();

  // REDUX
  const updatePending = useUpdateQuestionPending();
  const pending = updatePending;

  const isReadOnly = !hasAbilities('UpdateChoiceTaskQuestions');

  const handleSubmit = (data) => {
    return dispatch(updateQuestion(question.id, data))
      .then(() => { notify.default(`Task updated!`);})
      .catch(error => notify.error(format.error(error)));
  };

  return (
    <Can I='ReadChoiceTaskQuestions'>
      <div className='width-full'>
        <div className='stackVertical gap-24'>
          <Can I='UpdateChoiceTaskQuestions'>
            {question.building_task_count > 1 && (
              <Notebox
                title='Changes affect linked questions'
                body={`Any changes to this section will also affect all other building tasks linked to this question`}
                color='yellow'
                icon={{
                  symbol: 'Warning-2',
                  library: 'code',
                }}
              />
            )}
          </Can>

          <Form
            name='questionForm'
            onSubmit={handleSubmit}
            validation={validationSchema}
            className='stackVertical gap-16'
          >
            <FormText
              label='Title'
              name='title'
              value={question.title}
              disabled={isReadOnly}
              required
            />
            <FormText
              label='Subtitle'
              name='subtitle'
              disabled={isReadOnly}
              value={question.subtitle}
            />
            <FormToggle
              label='Allow multi select'
              name='allow_multi_select'
              disabled={isReadOnly}
              value={question.allow_multi_select}
            />
          </Form>
        </div>
        <Can I='ReadChoiceTaskQuestionOptions'>
          <div>
            <QuestionOptions question={question} task={task} />
          </div>
        </Can>

      </div>

      <Can I='UpdateChoiceTaskQuestions'>
        <div className='stackHorizontal gap-16 width-full marginTop-64'>
          <Button
            text='Save'
            color='primary'
            type='submit'
            form='questionForm'
            disabled={pending}
          />
          <Button
            text='Discard changes'
            color='secondary'
            onClick={e => {
              e.preventDefault();
              if (pending) return;
              return _handleReturn(location.state, history, property_id);
            }}
            disabled={pending}
          />
        </div>
      </Can>
    </Can>
  );
};
