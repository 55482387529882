import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './AnimatedCheckmark.module.scss';

export const AnimatedCheckmark = ({
  isChecked=false,
  fast=false,
  className
}) => (
  <svg
    className={classNames(
      CSS.checkmark,
      {[CSS.checked]: isChecked},
      {[CSS.fast]: fast},
      className,
    )}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"
  >
    <circle className={CSS.circle} cx="26" cy="26" r="25" fill="none" />
    <path className={CSS.check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
);

AnimatedCheckmark.propTypes = {
  /** Control value for checked state */
  isChecked: PropTypes.bool,
  /** Animation speed option flag */
  fast: PropTypes.bool,
};
