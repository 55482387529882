import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { LoaderOverlay, Icon, TabSlider } from '@moved/ui';
import { format, useUser } from '@moved/services';

import { useProperty, usePropertyPending, } from '../actions/selectors';

import CSS from './styles/PropertyDetails.module.scss';

export const PropertyDetailsBreadcrumb = ({ label, params }) => {
  const property = useProperty(params?.property_id);
  return property?.name ?? label;
};

export const PropertyDetailsTooltip = ({ params }) => {
  const property = useProperty(params?.property_id);
  return property ? format.address(property) : null;
};

export const PropertyDetails = ({ children }) => {
  // HOOKS
  const history = useHistory();
  const location = useLocation();
  const { property_id } = useParams();
  const { hasAbilities } = useUser();
  const property = useProperty(property_id);
  const pending = usePropertyPending();

  // Sub view
  const { pathname } = location;
  const basePath = `/admin/properties/${property_id}`;
  const relativePathSegments = pathname.split('/').slice(4);
  const activeTab = relativePathSegments[0] === 'task-lists' ? relativePathSegments.join('/') : relativePathSegments[0];

  // Tabs must match the available children routes
  const tabs = [
    ...(property?.building_task_lists ?? []).map(taskList => ({
      label: `${taskList?.move_step_type?.display_name} tasks`,
      value: `task-lists/${taskList.id}`,
    })),
    {label: 'Settings', value: 'settings'},
    hasAbilities('CreateTenants') && { label: 'Admin toolbox', value: 'actions' },
    hasAbilities('ReadFeedSyncs') && { label: 'PMS Integrations', value: 'integrations' }
  ].filter(v=>v);

  return (
    <>
      {pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            <div className={CSS.back_btn} onClick={() => history.push(`/admin/properties${Cookies.get('propertySearch') ?? ''}`)}>
              <Icon symbol='Arrow-left' library='navigation' size='32px' />
            </div>
            <h1>{ property?.name }</h1>
          </div>
          <div className={CSS.subtitle}>
            <p>{ format.address(property) }</p>
          </div>
        </div>

        <div className={CSS.features}>
          <TabSlider
            list={tabs}
            active={activeTab}
            callback={newTab => history.push(`${basePath}/${newTab}`, location.state)}
          />

          <div className={CSS.view}>
            { children }
          </div>

        </div>

      </div>
    </>
  );
};
