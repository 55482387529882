import React from 'react';

import { DynamicForm, AtomSpinner, Modal, Button } from '@moved/ui';
import { useModal } from '@moved/services';

import { AdminContentRenderer } from '../../../../../../../common';
import { useOrientationContents, useOrientationContentsPending } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const ChooseChapters = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {

  // REDUX
  const chapters = useOrientationContents(id);
  const pending  = useOrientationContentsPending();
  const updatePending = false;

  const modal = useModal();

  const _showPreviewModal = (e, content) => {
    e.stopPropagation();
    modal.openLegacy(
      <div className={CSS.editor_preview}>
        <AdminContentRenderer content={content} />
      </div>
    );
  };

  const onSubmit = data => {
    setDataCollection({ ...dataCollection, ...data, building_ids: [] });
    return setProgress(progress+1);
  }

  const fields = [
    {
      type: 'itemList',
      name: 'content_editor_content_ids',
      required: 'You must select at least one chapter.',
      isMulti: true,
      isNumeric: true,
      customItemClass: CSS.item,
      value: dataCollection.content_editor_content_ids,
      options: chapters.map(chapter => {
        return {
          label: (
            <div className={CSS.label}>
              <div className={CSS.grow}>
                <div>{chapter.title}</div>
                <div className={CSS.chapter_actions}>
                  <div className={CSS.preview} onClick={e => _showPreviewModal(e, chapter.content)}>Preview content</div>
                </div>
              </div>
            </div>
          ),
          value: chapter.id,
        };
      }),
    },
  ];

  if(pending) return <AtomSpinner/>;

  return (
    <>
      <Modal.Title><span className={CSS.progress}>{progress}/{length}</span> Select chapters</Modal.Title>

      <Modal.Content>
        <DynamicForm
          id='chapter-form'
          formStyle='underline'
          fields={fields}
          onSubmit={onSubmit}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          disabled={updatePending}
          onClick={() => modal.close()}
        />
        <Button
          text='Next'
          color='primary'
          icon={{ symbol: 'Arrow-right', library: 'navigation' }}
          iconPosition='right'
          id='chapter_form_button'
          form="chapter-form"
          disabled={updatePending}
        />
      </Modal.Actions>
    </>
  );
};
