import React from 'react';
import { useParams } from 'react-router-dom';

import { taskTypes } from '../types';

import { DefaultConfiguration } from './DefaultConfiguration';
import { useTaskDetails } from '../actions/selectors';

export const TaskDetailsBreadcrumb = ({ label, params }) => {
  const task = useTaskDetails(params?.task_id);
  return task?.title ?? label;
};

export const TaskDetails = () => {
  // HOOKS
  const { task_id } = useParams();

  // REDUX
  const task = useTaskDetails(task_id);

  const { Configuration=DefaultConfiguration } = taskTypes[task.task_type_name] || {};
  return <Configuration task={task} />;

};
