import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { useUser } from '@moved/services';

import { useClient } from '../../../../common/actions/selectors';

import { getTenantEventSearch, getBuildings } from '../actions/';
import { useTenantEventSearch, useTenantEventSearchPending, } from '../actions/selectors';
import { getClientSearch } from '../../../configuration/clients/actions';
import { useClientSearch } from '../../../configuration/clients/actions/selectors'

import { TenantEventsSearch } from './TenantEventsSearch';

// FUTURE: consolidate this with TenantEventSearch (no need for wrapper component)
export const TenantEventsSearchWrapper = () => {
  const dispatch = useDispatch();
  const { hasDomain, getDomainId } = useUser();
  const clients = useClientSearch(); // only used if admin user

  const isClientUser = hasDomain('client');
  const partner = useClient(isClientUser && getDomainId('client')); // only used if client user

  useEffect(() => {
    if(!isClientUser) dispatch(getClientSearch({ limit: 500 }));
  },[]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Admin Abode Moves : Moved</title>
      </Helmet>

      <TenantEventsSearch
        getTenantEvents={getTenantEventSearch}
        getBuildings={getBuildings}
        useTenantEvents={useTenantEventSearch}
        useTenantEventsPending={useTenantEventSearchPending}
        partner={partner}
        clients={!isClientUser && clients.activeSet}
      />
    </>
  )
};
