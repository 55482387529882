import React from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CSS from './Tooltip.module.scss';

export const Tooltip = ({
  tooltip,
  children,
  className,
  tooltipClassName,
  placement='top',
  // Advanced properties
  onShow,
  onHide,
  show,
  mountToBody,
}) => {

  const config = {
    placement: placement,
    trigger: 'hover',
    offset: [0,10],
    delayHide: 100,
    visible: show,
    onVisibleChange: (isVisible) => {
      if(show != null) return;
      if(isVisible && onShow) onShow();
      if(!isVisible && onHide) onHide();
    }
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(config);

  const tooltipElement = tooltip && (
    <div
      ref={setTooltipRef}
      {...getTooltipProps({ className: classNames(CSS.tooltip, tooltipClassName) })}
    >
      { tooltip }
      <div {...getArrowProps({ className: CSS.arrow })} />
    </div>
  );

  return (
    <>
      <div ref={setTriggerRef} className={classNames(CSS.trigger, className)}>
        { children }
      </div>
      { visible && (
        mountToBody ? ReactDOM.createPortal(tooltipElement, document.body) : tooltipElement
      )}
    </>
  );
};

Tooltip.propTypes = {
  /** the tooltip contents */
  tooltip: PropTypes.node.isRequired,
  /** the content to be wrapped in the trigger for the popover */
  children: PropTypes.node.isRequired,
  /** className to add to the wrapper element */
  className: PropTypes.string,
  /** className to add to the tooltip element */
  tooltipClassName: PropTypes.string,
  /** override the default placement direction of the tooltip */
  placement: PropTypes.oneOf(['top','right','bottom','left']),
  /** (advanced) callback function when popover is shown (only for uncontrolled)*/
  onShow: PropTypes.func,
  /** (advanced) callback function when popover is hidden (only for uncontrolled)*/
  onHide: PropTypes.func,
  /** (advanced) boolean value to turn this into a controlled tooltip */
  show: PropTypes.bool,
  /** (advanced) use portal to put the tooltip on the body element instead of sibling of trigger */
  mountToBody: PropTypes.bool
};
