import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider, ServicesProvider, RouterProvider } from '../../contexts';

export const Providers = ({
  children,
  store,
  exclude,
  routeTree,
  defaultDomains,
  basePath,
}) => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <UserProvider>
          <RouterProvider {...{ routeTree, defaultDomains, basePath }}>
            <ServicesProvider exclude={exclude}>
              { children }
            </ServicesProvider>
          </RouterProvider>
        </UserProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};
