import React, { Fragment } from 'react';

import { CompleteCard, Modal, Button } from '@moved/ui';
import { useModal } from '@moved/services';

export const SuccessScreen = ({ id, success, dataCollection, progress, setProgress }) => {
  const modal = useModal();

  const { content_editor_contents: chapters=[{}] } = success;

  return (
      <>
        <Modal.Content>
          <CompleteCard status='approved' title='Success!' detailsTitle={null}>
            <div className='labelL contentSecondary padding-0-40 txt-center'>
              {chapters.map((chapter, idx) => {
                const lastItem = idx+1 >= chapters.length;
                return (
                  <Fragment key={chapter.id}>
                    {(lastItem && chapters.length > 1) && '& '}
                    &ldquo;<span className='contentPrimary'>{chapter.title}</span>&rdquo;
                    {!lastItem && `, `}
                  </Fragment>
                )
              })} {chapters?.length > 1 ? 'have' : 'has'} been cloned to {dataCollection?.building_ids?.length}
              {dataCollection?.building_ids?.length > 1 ? ' properties' : ' property'}
            </div>
          </CompleteCard>
        </Modal.Content>

        <Modal.Actions>
          <Button
            text='Done'
            onClick={() => modal.close()}
          />
        </Modal.Actions>
      </>
  );
}
