import React, { useMemo } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { Transitions } from '@moved/ui'; // TODO: uh oh, this is a circular dependency

import { findMatchingRoutes } from '../../services/router';

export const RenderRoutes = ({ routes, basePath }) => {
  const location = useLocation();

  // this is duplicative of the logic in the router context, but is necessary for screen transitions:
  // while the context updates immediately, the location is persisted until the exiting screen is unmounted
  const activeRoutes = findMatchingRoutes(routes, basePath, location.pathname);
  const { route: activeRoute, match } = activeRoutes?.find(({ route }) => routes.includes(route)) ?? {};

  const pageKey = (activeRoute?.viewKey && activeRoute.viewKey(match.params)) ?? location.key;

  const previousRoute = usePrevious(activeRoute);
  const activeTransition = useMemo(
    () => activeRoute?.transitions?.in ?? previousRoute?.transitions?.out ?? activeRoute?.transition,
    [activeRoute]
  );

  const getRoutePath = route => `${basePath}${route.path}`.replace(/\/+/g, '/');

  return (
    <Transitions
      pageKey={pageKey}
      transition={activeTransition}
    >
      <Switch location={location}>
        { routes.map(route => (
          route.redirect ? (
            <Redirect
              key={getRoutePath(route)}
              from={getRoutePath(route)}
              to={route.to}
              exact={route.exact}
              push={route.push}
            />
          ) : route.component ? (
            <Route
              key={getRoutePath(route)}
              path={getRoutePath(route)}
              exact={route.exact}
              render={() => (
                <route.component
                  parent={basePath}
                  match={match}
                  {...route.passProps}
                >
                  { route.children && (
                    <RenderRoutes
                      routes={route.children}
                      basePath={getRoutePath(route)}
                    />
                  )}
                </route.component>
              )}
            />
          ) : route.children ? (
            <Route
              key={getRoutePath(route)}
              path={getRoutePath(route)}
              exact={route.exact}
              render={() => (
                <RenderRoutes
                  routes={route.children}
                  basePath={getRoutePath(route)}
                />
              )}
            />
          ) : null
        ))}
      </Switch>
    </Transitions>
  )
};
