import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Badge, Icon } from '@moved/ui';

import CSS from './styles/PropertyResult.module.scss';

const _renderAddress = (address) => {
  address = address || {};
  return (
    <div className={CSS.address_line}>
      <span className={CSS.address_line_one}>{[address.street,address.unit].filter((v)=>v).join(' ')}</span>
      <span className={CSS.address_line_two}>
        {[
          address.city,
          [
            address.state,
            address.zipcode,
          ].filter((v)=>v).join(' ')
        ].filter((v)=>v).join(', ')}
      </span>
    </div>
  );

};

export const PropertyResult = ({ property, active }) => {
  const partner = property.partner || {};

  return (
    <Link
      className={classNames(CSS.box, { [CSS.box_active]: active })}
      to={`/admin/properties/${property.id}`}>

      <div className={CSS.box_top}>

        <div className={CSS.name}>
          <div
            className={CSS.splash}
            style={{
              ...{backgroundImage: property.dashboard_image_url ?
                `url('${property.dashboard_image_url}')`
                : null,
              }
            }}
          />
          <div className={CSS.name_text}>{property.name}</div>
        </div>

        <div className={CSS.partner_name}>
          {partner.name}
        </div>

        <div className={CSS.address}>
          {_renderAddress(property)}
        </div>

        <div className={CSS.status}>
          <Badge
            text={property.status}
            type={property.status === 'decommissioned' ? 'default' : property.status === 'launched' ? 'success' : 'info'}
          />
        </div>

        <div className={CSS.status_date}>
          {moment(property.status_date).format('MMM DD, YYYY')}
        </div>

        <div className={CSS.moves}>
          {property.upcoming_moves_count}
        </div>

        <div className={CSS.arrow}>
          <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow_icon} />
        </div>

      </div>

    </Link>
  );
};
