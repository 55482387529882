import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import classNames from 'classnames';

import { Modal, AtomSpinner, Button, Text, Form, FormOptionList } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services'

import { PropertyAddress } from './AssignedProperty';
import { assignAdminProperty } from '../actions';
import { useCreateAdminPending } from '../actions/selectors';
import { getPropertiesSearch } from '../../properties/actions';
import { usePropertiesSearch, usePropertiesSearchPending } from '../../properties/actions/selectors';

import CSS from './styles/AssignPropertiesModal.module.scss';

export const AssignPropertiesModal = ({ admin }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();
  const pending = useCreateAdminPending();
  const { activeSet: buildings } = usePropertiesSearch();
  const buildingsPending = usePropertiesSearchPending();
  const [filter, setFilter] = useState('');
  const [valid, setValid] = useState(false);

  const handleSubmit = (data) => {
    dispatch(assignAdminProperty(admin.id,data))
      .then(() => modal.close())
      .catch(error => notify.error(format.error(error)));
  };

  useEffect(() => {
    dispatch(getPropertiesSearch({
      partner_ids: admin?.partner?.id,
      limit: 500,
    }));
  },[admin, dispatch]);

  const availableBuildings = buildings
    .filter(building => admin.building_admins.find(adminBuilding => adminBuilding?.building?.id === building?.id) == null)
    .filter(building => building?.name?.toLowerCase().includes(filter.toLowerCase()));

  if(buildingsPending) return (
    <div className={CSS.assignment}>
      <div className={CSS.title}>
        <h3>Assign property</h3>
      </div>
      <AtomSpinner />
    </div>
  );

  return (
    <Modal className={CSS.container}>
      <Modal.Title>Assign { admin?.partner?.name ?? '' } properties</Modal.Title>
      <Modal.Content>
        {availableBuildings.length > 0 ? (
          <div className='stackVertical gap-20'>
            <Text
              name='buildingFilter'
              label='Filter properties'
              placeholder='Search...'
              icon={{ library: 'general', symbol: 'Search' }}
              onChange={({ buildingFilter }) => setFilter(buildingFilter)}
              value={filter}
            />
            <Form
              name='assignedProperties'
              onSubmit={handleSubmit}
              className={classNames('stackVertical scrollable', CSS.form)}
              validation={Yup.object().shape({
                building_ids: Yup.array().min(1,'Please select at least one property'),
              })}
            >
              <FormOptionList
                name='building_ids'
                allowMultiple
                size='small'
                onChange={({ building_ids }) => setValid(building_ids.length > 0)}
                options={
                  availableBuildings.map(building => ({
                    value: building.id,
                    className: CSS.option,
                    label: (
                      <div className='stackHorizontal gap-20 items-center'>
                        <div
                          className={CSS.image}
                          style={{ backgroundImage: (
                            building.dashboard_image_url ? `url('${building.dashboard_image_url}')` : null
                          )}}
                        />
                        <div className={classNames('labelM contentPrimary',CSS.name)}>{building.name}</div>
                        <div className={CSS.address}>
                          <PropertyAddress address={building} />
                        </div>
                      </div>
                    )
                  }))
                }
              />
            </Form>
          </div>
        ) : (
          <div className='labelM contentSecondary'>
            Sorry, there are no additional {admin?.partner?.name} properties to assign to this admin.
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => modal.close()}
        />
        {availableBuildings.length > 0 && (
          <Button
            text='Assign'
            color='primary'
            form='assignedProperties'
            disabled={pending || !valid}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}
