import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_APPROVALS_SEARCH',
  request: (params={},cancelToken) => request.get(`/partners/approvals`, { params, cancelToken }).then(r => r.data),
  selector: (state) => state?.approvalSearch,
  reducers: {
    success: (state, { response, params: [searchParams] }) => {
      return {
        ...state,
        approvalSearch: {
          params: searchParams,
          totalResults: response?.pagination.total_items,
          activeSet: response?.data.requests,
        },
      }
    },
    failure: (state, { params: [searchParams] }) => {
      return {
        ...state,
        approvalSearch: {
          params: searchParams,
          totalResults: 0,
          activeSet: [],
        }
      }
    },
  }
};

export const {
  reducer,
  action,
  useResource: useApprovals,
  usePending: useApprovalsPending,
} = generate.redux(creator);

export const initialState = {
  approvalSearch: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
