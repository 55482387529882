import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { DynamicForm, AtomSpinner, Modal, Tooltip, Button } from '@moved/ui';
import { useModal, useNotify, format } from '@moved/services';

import { PropertyAddress } from '../../../../../admins';
import { getEligibleProperties } from '../../actions/';
import { useEligibleProperties, useEligiblePropertiesPending, useOrientationContents } from '../../actions/selectors';

import CSS from './styles/CloneChaptersModal.module.scss';

export const ChooseProperties = ({ id, dataCollection, setDataCollection, progress, setProgress, length }) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const notify = useNotify();

  const { content_editor_content_ids: chapters } = dataCollection;

  const eligible = useEligibleProperties(id);
  const pending  = useEligiblePropertiesPending();
  const contents = useOrientationContents(id);

  useEffect(() => {
    dispatch(getEligibleProperties(id, {content_editor_content_ids: chapters.length > 0 ? chapters.join(',') : null,}))
      .catch(error => notify.error(format.error(error)));
  },[]); // eslint-disable-line

  if(pending) return <AtomSpinner/>;


  const fields = [
    {
      type: 'itemList',
      name: 'building_ids',
      required: 'You must select at least one property.',
      isMulti: true,
      isNumeric: true,
      customItemClass: CSS.building_item,
      value: dataCollection.building_ids,
      options: eligible.map(property => {
        const building = property.building || {};
        return {
          label: (
            <>
            <div className={classNames(CSS.label)}>
              <div
                className={CSS.splash}
                style={{
                  ...{backgroundImage: building.dashboard_image_url ?
                    `url('${building.dashboard_image_url}')`
                    : null,
                  }
                }}
              />
              <div className={CSS.grow}>
                <div>{building.name}</div>
                <div className={CSS.address}>
                  <PropertyAddress address={building} />
                </div>
              </div>

            </div>
            {property.disabled && (
              <div className={CSS.disable_pill_holder}>
                <Tooltip tooltip={property.disabled_description} tooltipClassName='width-160'>
                  <div className={CSS.disable_pill}>
                    <span className={CSS.pill_text}>{property.disabled_label.replace("_", " ").toLowerCase()}</span>
                  </div>
                </Tooltip>
              </div>
            )}
            </>
          ),
          disabled: property.disabled,
          value: property.building.id
        };
      }),
    },
  ];

  const onSubmit = data => {
    setDataCollection({ ...dataCollection, ...data});
    return setProgress(progress + 1);
  };

  const chosenChapters = chapters.map(id => contents.find(content => parseInt(content.id) === parseInt(id)));

  return (
    <>
      <Modal.Title>
        <span className={CSS.progress}>{progress}/{length}</span>&nbsp;
        Clone {(chosenChapters.length > 1) ? `${chosenChapters.length} chapters` : (`'${chosenChapters[0].title}'`)} to
      </Modal.Title>

      <Modal.Content>
        {eligible.length > 0 ? (
          <DynamicForm
            id='property-form'
            formStyle='underline'
            fields={fields}
            onSubmit={onSubmit}
          />
        ) : (
          <p>{`There are no eligible properties for cloning the selected chapter${chosenChapters.length > 1 ? 's' : ''}.`}</p>
        )}
      </Modal.Content>

      <Modal.Actions>
        <div className='stackHorizontal gap-16 justify-start flex-auto'>
          <Button
            text='Back'
            icon={{ symbol: 'Arrow-left', library: 'navigation' }}
            color='secondary'
            onClick={() => setProgress(progress - 1)}
          />
        </div>
        <div className='stackHorizontal gap-16 justify-end flex-auto'>
          <Button
            text='Cancel'
            color='secondary'
            onClick={() => modal.close()}
          />
          <Button
            text='Next'
            color='primary'
            icon={{ symbol: 'Arrow-right', library: 'navigation' }}
            iconPosition='right'
            id='property_form_button'
            form="property-form"
          />
        </div>
      </Modal.Actions>
    </>
  );
};
