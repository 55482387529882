import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { useUser, useNotify, useRouter} from '@moved/services';
import { LogoBlock } from '@moved/product';
import { Icon, Breadcrumbs } from '@moved/ui';

import { useClient } from '../actions/selectors';
import { AppNavigation } from './AppNavigation';

import abode_logo from '../images/abode-logo.svg';
import CSS from './styles/Layout.module.scss';

export const Layout = ({ children }) => {
  // HOOKS
  const { logout, hasDomain, getDomainId, Can } = useUser();
  const history = useHistory();
  const notify = useNotify();
  const { activeRoutes } = useRouter();

  const partner = useClient(getDomainId('client')); // only clients will have this data

  const signOut = () => {
    logout() // resets the redux store to initial state
      .then(() => {
        history.push('/signin'+(partner ? `/${partner.slug}` : ''));
        notify.default('Someone successfully logged out, but we forgot who it was.');
      });
  }

  const breadcrumbs = activeRoutes
    .filter(({ route }) => route.breadcrumb)
    .map(({ route, match }) => ({
      ...route.breadcrumb,
      url: match.url,
      params: match.params,
    }));

  const navigation = [
    {
      label: 'Activity',
      items: [
        get(partner,'settings.approvals_dashboard_enabled') && {
          urlPath: '/admin/approvals?statuses=pending-approval',
          label: 'Approvals',
          icon: {
            symbol: 'Like',
            library: 'general',
          },
          ability: 'ReadApprovableRequests',
        },
        {
          urlPath: '/admin/moves?filter=upcoming',
          label: 'Moves',
          icon: {
            symbol: 'Sign-in',
            library: 'navigation',
          },
          ability: ['ReadTenants','ReadTenantEvents'],
        },
        get(partner,'settings.admin_calendar_enabled') && {
          urlPath: '/admin/calendars',
          label: 'Calendar',
          icon: {
            symbol: 'Date-to',
            library: 'code',
          },
          ability: 'ReadBuildingCalendars',
        },
      ].filter(v=>v),
    },
    {
      label: 'Configuration',
      items: [
        get(partner,'id') && {
          urlPath: `/admin/clients/${partner.id}`,
          label: 'Client',
          icon: {
            symbol: 'Crew-3',
            library: 'people',
          },
          ability: 'ReadPartners',
        },

        !get(partner,'id') && {
          urlPath: '/admin/clients',
          label: 'Clients',
          icon: {
            symbol: 'Crew-3',
            library: 'people',
          },
          ability: 'ReadPartners',
        },

        {
          urlPath: '/admin/properties',
          label: 'Properties',
          icon: {
            symbol: 'Building',
            library: 'home',
          },
          ability: 'ReadBuildings',
        },

        {
          urlPath: '/admin/admins',
          label: 'Admins',
          icon: {
            symbol: 'Binocular',
            library: 'general',
          },
          ability: 'ReadAdmins',
        },
      ].filter(v=>v),
    },
  ];

  return (
    <div className={CSS.template}>
      <AppNavigation
        logos={(
          get(partner,'logo_url') ? (
            <LogoBlock
              logo={get(partner,'logo_url')}
              redirect={`/admin/moves?filter=upcoming`}
            />
          ) : (
            <Link to={`/admin/properties`} className={CSS.logo_link}>
              <img src={abode_logo} alt="Admin Abode" className={CSS.partner_logo} />
            </Link>
          )
        )}
        navItems={navigation}
        signOut={signOut}
        profileContent={hasDomain('cx') && (
          <Can I="ReadMoves">
            <Link to={`/cx/moves`} className={CSS.cx_link}>
              <Icon size='24px' symbol={'Redo'} className={CSS.icon} />
              <span className={CSS.label}>Switch to CX</span>
            </Link>
          </Can>
        )}
      />
      <section className={CSS.content}>
        <div className={CSS.area}>
          <Breadcrumbs breadcrumbs={breadcrumbs} className={CSS.breadcrumbs} />
          {children}
        </div>
      </section>
    </div>
  );
};

export const withLayout = (Wrapped) => (props) => (<Layout><Wrapped {...props}/></Layout>);
