import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import { Collapsible } from '../Collapsible';
import { Icon } from '../Icon';

import CSS from './Accordion.module.scss';

export const Accordion = ({
  label,
  content,
  open,
  isControlled,
  onChange,
  disabled,
  className,
}) => {
  const [isOpen, setIsOpen] = useOptionalControl(open, isControlled);

  const toggleOpen = () => {
    if(disabled) return;
    setIsOpen(!isOpen);
    onChange && onChange(!isOpen);
  };

  return (
    <div className={classNames(
      'stackVertical',
      CSS.accordion,
      {[CSS.open]: isOpen},
      className,
    )}>
      <div
        className={classNames(
          'stackHorizontal gap-20 items-center justify-between',
          CSS.labelBar,
        )}
        onClick={toggleOpen}
      >
        <div className={classNames('labelM contentSecondary', CSS.label)}>
          { label }
        </div>
        <Icon
          library='navigation'
          symbol='Chevron-down'
          className={classNames('flex-none', CSS.icon)}
          color='inherit'
        />
      </div>
      <Collapsible open={isOpen}>
        {(isOpen) => typeof content === 'function' ? (
          <div className={CSS.content}>
            {content(isOpen, toggleOpen)}
          </div>
        ) : (
          <div className={CSS.content}>
            {content}
          </div>
        )}
      </Collapsible>
    </div>
  );
}

Accordion.propTypes = {
  /** Label to display on the accordion toggle bar */
  label: PropTypes.node,
  /** Content to display when the accordion is open. If a function, it will be called with the current open state and a function to toggle the open state. */
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** Flag to toggle if the accordion is open if controlled or initial state if uncontrolled */
  open: PropTypes.bool,
  /** Flag to toggle if controlled or uncontrolled component */
  isControlled: PropTypes.bool,
  /** Callback for when the accordion is toggled */
  onChange: PropTypes.func,
  /** Flag to disable the accordion */
  disabled: PropTypes.bool,
  /** Classname to add to the component */
  className: PropTypes.string,
};
