import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { isNil, isEqual, isEmpty } from 'lodash';

import { useQuery, request } from '@moved/services';
import { LoaderOverlay, Pagination, BouncingDots, Icon, EmptyContent } from '@moved/ui';

import { movesSearch } from '../actions';
import { useMovesSearch, useMovesSearchPending } from '../actions/selectors';
import { MovesSearchBar, platformDefaultParams } from './MovesSearchBar';
import { MovesSearchResult } from './MovesSearchResult';

import CSS from '../styles/MovesSearch.module.scss';

// convenience function for building a set of query params
const getSearchString = (params) => {
  return Object.keys(params)
    .filter(param => !isNil(params[param]))
    .map(param => `${param}=${encodeURIComponent(params[param])}`)
    .join('&');
};

export const MovesSearch = ({ children }) => {
  // HOOKS
  const history = useHistory();
  const dispatch = useDispatch();
  const resultsScroller = useRef(); // used for the results list panel
  const currentQueryParams = useQuery();

  // determine what the default query params are with personalization
  const personalDefaultParams = JSON.parse(localStorage.getItem('cxSearchDefaults'));
  let defaultParams = {
    ...platformDefaultParams,
    ...(currentQueryParams.direct ? null : personalDefaultParams), // if direct link ignore personal defaults so that all results can show
  };

  // need to determine if a result is active and which one
  // (the param belongs to a child route so we must do a manual match)
  const routeMatch = useRouteMatch(`/cx/moves/:id`);
  const activeMoveId = routeMatch && parseInt(routeMatch?.params?.id);

  // determine the individual active params and preprocess them as necessary
  const search = currentQueryParams.direct || currentQueryParams.search || defaultParams.search;
  const status = currentQueryParams.status || defaultParams.status;
  const sort = currentQueryParams.sort || defaultParams.sort;
  const order = currentQueryParams.order || defaultParams.order;
  const page = parseInt(currentQueryParams.page) || defaultParams.page; // enforce int type
  const per_page = parseInt(currentQueryParams.per_page) || defaultParams.per_page; // enforce int type

  // assemble all active params for convenience
  const activeParams = { search, status, sort, order, page, per_page };

  // move search redux state
  const { moves=[], totalResults, totalPages } = useMovesSearch();
  const pending = useMovesSearchPending();

  // convenience function for updating a set of query params
  const updateQuery = (newParams, resetActive) => {
    const updatedParams = {
      ...activeParams,
      page: defaultParams.page, // reset to the first page
      ...newParams,
    };
    return history.push({
      pathname: resetActive ? `/cx/moves` : history?.location?.pathname,
      search: getSearchString(updatedParams),
    });
  };

  // handler for resetting all filter query params back to defaults (but not sorting)
  const clearQuery = () => {
    updateQuery({
      search: platformDefaultParams.search,
      status: platformDefaultParams.status,
    });
  };

  // function to set the current search as the new personal default search via localStorage
  const savePersonalDefaultParams = () => {
    // not all params are savable, this declares which ones to include
    const activeSavableParams = { status, sort, order, per_page };
    // loop through savableParams and append ones that are different than the platformDefaults
    const paramsToSave = Object.keys(activeSavableParams)
      .filter(param => !isEqual(activeSavableParams[param], platformDefaultParams[param]))
      .reduce((currentSet, param) => ({...currentSet, [param]:activeSavableParams[param]}), {});
    // if none are set different than platformDefaults, just clear the key
    if(isEmpty(paramsToSave)) localStorage.removeItem('cxSearchDefaults');
    // else update the localStorage with the new personal defaults
    else localStorage.setItem('cxSearchDefaults', JSON.stringify(paramsToSave));
  };

  // handler for loading the moves for the current query params
  const loadMoves = (cancelToken) => {
    // Scroll to top of page
    resultsScroller.current.scrollTo({ top: 0, behavior: 'smooth' });
    // make the request with the active params mapped to the API contract
    return dispatch(movesSearch({
      customer_status: status === 'all' ? null : status,
      keywords: search,
      sort_by: sort,
      sort_order: order,
      page,
      limit: per_page,
    },cancelToken));
  };

  // handler for selecting a move
  const onSelectMove = (move) => {
    if(move.id === activeMoveId) return;
    history.push({
      pathname: `/cx/moves/${move.id}`,
      search: getSearchString(activeParams),
    });
  };

  // enforce the url gets all of the active search params added on page load
  useEffect(() => updateQuery({page:page}),[]) // eslint-disable-line

  // refresh the move search results any time any query param is updated
  useEffect(() => {
    const { cancel, token } = request.CancelToken.source();
    loadMoves(token)
      .then(results => {
        // if there is not an active move after loading moves, select the first one
        const firstResult = results?.data?.at(0);
        if(!activeMoveId && firstResult) onSelectMove(firstResult);
      });
    return () => cancel('cancel previous search');
  },[status, sort, order, search, page, per_page]); // eslint-disable-line

  return (
    <>
      <Helmet>
        <title>Moves : CX Admin</title>
      </Helmet>

      { pending && (<LoaderOverlay />)}

      <div className={CSS.content}>

        <div className={CSS.search_column}>

          <MovesSearchBar
            updateQuery={updateQuery}
            clearQuery={clearQuery}
            activeParams={{status,search,sort,order,page,per_page}}
            saveSearch={savePersonalDefaultParams}
          />

          {/* Results Count & Refresh */}
          <div className={CSS.results_summary}>
            { pending ? (<BouncingDots/>) : (
              <>
                <span>Results</span>{' '}
                {search && (<span>for &ldquo;<span className={CSS.search_terms}>{search}</span>&rdquo;</span>)}{' '}
                <span>{per_page*(page-1)+1} - {Math.min(per_page*page,totalResults)} of</span>{' '}
                <span className={CSS.search_results}>{ totalResults }</span>
              </>
            )}
            <Icon library='general' symbol='Update'
              className={classNames(CSS.refresh,{[CSS.loading]:pending})}
              onClick={() => loadMoves()}
            />
          </div>

          {/* Results */}
          <div className={CSS.results} ref={resultsScroller}>
            { moves.length ? moves.map(move => (
              <MovesSearchResult move={move} key={move.id} onClick={onSelectMove} isActive={move.id === activeMoveId} />
            )) : (
              <EmptyContent message={'No moves match your search criteria!'} iconSize='50px' />
            )}
          </div>

          {/* Page nav */}
          { totalPages > 1 && (
            <div className={CSS.pagination}>
              <Pagination
                page={page}
                pageCount={totalPages}
                onPageChange={(newPage) => updateQuery({page:newPage})}
              />
            </div>
          )}

        </div>

        <div className={CSS.active_move}>
          { children }
        </div>

      </div>
    </>
  );
};
