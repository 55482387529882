import { useState, useCallback } from 'react';
import * as getScrollParent from 'scrollparent';

export const useScrollParent = () => {
  const [scrollParent, setScrollParent] = useState();
  const ref = useCallback(
    element => setScrollParent(getScrollParent(element)),
    []
  );
  return {
    ref,
    scrollParent,
  };
}
