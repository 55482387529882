import { AdminSearchWrapper, AdminDetails } from './components';

const routes = [
  {
    path: '/admins',
    name: 'AdminAdminSearch',
    component: AdminSearchWrapper,
    exact: true,
    restrict: ['abode'],
    breadcrumb: {
      label: 'Admins',
      icon: {
        symbol: 'Binocular',
        library: 'general',
      },
    },
  },
  {
    path: '/admins/:id(\\d+)',
    name: 'AdminAdminDetails',
    component: AdminDetails,
    exact: true,
    restrict: ['abode'],
    breadcrumb: {
      label: 'Admin details',
    },
  },
];

export default routes;
