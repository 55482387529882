import { TenantEventsSearchWrapper } from './components/TenantEventsSearchWrapper';
import { TenantEventDetails } from './components/TenantEventDetails';

const routes = [
  {
    path: '/moves',
    name: 'AdminMoves',
    breadcrumb: {
      label: 'Moves',
      icon: { symbol: 'Sign-in', library: 'navigation' },
    },
    children: [
      {
        path: '/',
        name: 'AdminMovesSearch',
        component: TenantEventsSearchWrapper,
        exact: true,
      },
      {
        path: '/:id(\\d+)',
        name: 'AdminMoveDetails',
        component: TenantEventDetails,
        exact: true,
        breadcrumb: { label: 'Move details' }
      },
    ],
  },
];

export default routes;
