import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMIN_ADMINS_ASSIGN_ADMIN_PROPERTY',
  request: (id, data={}) => request.post(`/admins/admins/${id}/building-admins`, data).then(r => r?.data?.data?.admin),
  reducers: {
    success: (state, { response: admin }) => ({
      ...state,
      admins: {
        ...state.admins,
        [admin.id]: admin,
      },
    })
  },
};

export const {
  reducer,
  action,
  usePending: useAssignAdminPropertyPending,
} = generate.redux(creator);

export const initialState = {
  admins: {},
};
