import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import { DynamicForm, Notebox } from '@moved/ui';

import { reschedule } from '../../actions';
import { useReschedulePending } from '../../actions/selectors';

import CSS from './RescheduleModal.module.scss';

export const RescheduleModal = ({ order={} }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const pending = useReschedulePending();

  // state
  const [values, setValues] = useState({
    service_date: moment().isSameOrBefore(order.service_date,'day') ? order.service_date : '',
    earliest_arrival_time: moment(order.earliest_arrival_time,'h:mmA').format('h:mma'),
    latest_arrival_time: moment(order.latest_arrival_time,'h:mmA').format('h:mma'),
  });
  const [startEnd, setStartEnd] = useState(moment(order.earliest_arrival_time,'h:mmA').add({hour: 1}).format('h:mma'));

  // CONVENIENCE FUNCTIONS
  const timeChange = (option, setFieldValue, name) => {
    let earliest, latest;
    if(name === 'earliest_arrival_time') {
      latest = values.latest_arrival_time;
      earliest = option.value;
      const startEnd = moment(earliest,'h:mma').add({hour: 1}).format('h:mma');
      setStartEnd(startEnd);
      if(moment(latest, 'h:mma').isBefore(moment(startEnd, 'h:mma'))) {
        setFieldValue('latest_arrival_time',startEnd,true);
      }
    }
  };

  // FORM
  const formFields = [
    {
      type: 'datePicker',
      name: 'service_date',
      label: 'Move Date',
      value: values.service_date,
      required: 'Service Date is required.',
      minDate: moment().format('YYYY-MM-DD'),
    },
    {
      label: 'Earliest Arrival Time',
      placeholder: 'Select a time',
      name: 'earliest_arrival_time',
      type: 'timePicker',
      value: values.earliest_arrival_time,
      min: '5:00am',
      max: '10:00pm',
      interval: ['00'],
      required: true,
      onChange: timeChange,
      half: true,
    },
    {
      label: 'Latest Arrival Time',
      placeholder: 'Select a time',
      name: 'latest_arrival_time',
      type: 'timePicker',
      value: values.latest_arrival_time,
      min: startEnd || '6:00am',
      max: '11:00pm',
      interval: ['00'],
      required: true,
      onChange: timeChange,
      half: true,
    },
  ];

  const handleSubmit = ({ earliest_arrival_time, latest_arrival_time, service_date }) => {
    if(pending) return;
    // format the data for serverside
    const data = {
      service_date,
      earliest_arrival_time:moment(earliest_arrival_time,'h:mma').format('HH:mm:ss'),
      latest_arrival_time: moment(latest_arrival_time,'h:mma').format('HH:mm:ss'),
    };
    dispatch(reschedule(order.id, order.order_number, data))
      .then((order) => {
        notify.default(`Move has been successfully rescheduled.`);
        modal.close(order);
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Reschedule Move</h3>
    <Notebox
      title='Note'
      body={`This action assumes both customer and vendor have already agreed to
        an updated move date and/or an updated arrival window. Upon submission, a
        confirmation email will be immediately sent to both parties.`}
      color='blue'
      className={CSS.note_box}
    />
    <DynamicForm
      id="reschedule-form"
      formStyle={'underline'}
      fields={formFields}
      onChange={setValues}
      onSubmit={handleSubmit}
    />
    <section className={classNames(CSS.actions)}>
      <span className={'btn-gray mr-15'} onClick={modal.close}>Cancel</span>
      <button className={classNames('btn-primary',{loading:pending})} type='submit' form='reschedule-form'>Update</button>
    </section>
  </>);

}
